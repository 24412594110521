import { graphql } from 'gatsby'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

function NotFound({ pageContext }) {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo seo={{ lang: pageContext.strapiLangKey }} />
      <main className="container text-center py-10">
        <section>
          <div className="mb-14">
            <h1>{t('Oops! That\'s a wrong turn.')}</h1>
            <p>{t('This page doesn\'t exist')}</p>
          </div>
          <Link className="button-main" to="/">{t('Back to home')}</Link>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query(
    $language: String!
  ) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      ...Locales
    }
  }
`

export default NotFound